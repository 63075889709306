import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import i1 from "./assets/images/Braintoadz.jpg";

const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 30px;

  @media (min-width: 900px) {
    width: 50px;
  }
  @media (min-width: 1000px) {
    width: 60px;
  }
  transition: width 0.5s;
`;

export const StyledImg = styled.img`
  width: 500px;
  height: 226px;
  @media (min-width: 660px) {
    width: 1200px;
    height: 543px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledLink = styled.a`
  font-size: 30;
  color: white;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  //const [proof, setProof] = useState([]);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getProof = async () => {

    const whitelist = await fetch("/config/addresses.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const tokens = await whitelist.json();

    let tab = [];
    tokens.map((token) => {
      tab.push(token.address);
    });

    const leaves = tab.map((address) => keccak256(address));
    const tree = new MerkleTree(leaves, keccak256, { sort: true });
    const root = tree.getHexRoot();
    //const leaf = keccak256("0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2");
    const leaf = keccak256(blockchain.account);
    const proof = tree.getHexProof(leaf);
    //const proofstring = JSON.stringify(proof);
    //console.log("root : " + root);
    //console.log("proof : " + proof);
    //console.log("proofstring : " + proofstring);
    setProof(proof);
  }

  const claimNFTs = () => {
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = 0;

    totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount, data.proof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 500) {
      newMintAmount = 500;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      //getProof();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 0, backgroundColor: "black" }}
      >
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "black",
              padding: 0,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
            >
          <s.Container ai={"top"} jc={"center"} fd={"row"}>
          <StyledLink target={"_blank"} href={"https://twitter.com/braintoadznft"}>
            <StyledLogo alt={"logo"} src={"/config/images/twitter.jpg"} />
          </StyledLink>

          <StyledLink target={"_blank"} href={"https://opensea.io/collection/braintoadz"}>
            <StyledLogo alt={"logo"} src={"/config/images/opensea.jpg"} />
          </StyledLink>

          <StyledLink target={"_blank"} href={"https://discord.gg/Y6mbw4Q8qH"}>
            <StyledLogo alt={"logo"} src={"/config/images/discord.jpg"} />
          </StyledLink>
          </s.Container>                    

            <StyledImg alt={"Braintoadz"} src={i1} />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "regular",
                color: "var(--accent-text)",
              }}
            >
              Braintoadz started as a concept drawing that transformed into a life of its own, 
              featured on every different surface toad is making their way into a world of beyond plain sight.
              Fitted with different styles toadz and their fam are ready to go to battle with the world.
              Without the love and strength from the whole fam Toad couldn't do it by themselves.
              So we are opening our arms with the BRAINTOADZ mint to allow you into our antics.
            </s.TextTitle>
            <s.SpacerMedium />
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            {data.presale && !data.mainsale && !data.approved ? (
                    <>
                    <s.TextTitle
                      style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 20}}
                    >
                      The presale has started but your wallet might not be in the presale list.
                    </s.TextTitle>
                    </>
            ) : (<></>)}
            {data.totalSupply >= CONFIG.MAX_SUPPLY || data.balance > 5 ? (
              <>
                  { data.balance > 500 ? (
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 20 }}
                    >
                        There is a 5 mint per wallet limit.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)", fontSize: 20 }}
                    >
                      You can try using another wallet or buy a {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerXSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                    </s.Container>                    
                  ) : (<></>)}
                  {data.totalSupply >= CONFIG.MAX_SUPPLY ? (
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                    The sale has ended.
                    </s.TextTitle>
                    <s.SpacerXSmall />
                    <s.TextDescription
                      style={{ textAlign: "center", color: "var(--accent-text)" }}
                    >
                      You can try using another wallet or buy a {CONFIG.NFT_NAME} on
                    </s.TextDescription>
                    <s.SpacerXSmall />
                    <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                      {CONFIG.MARKETPLACE}
                    </StyledLink>
                    </s.Container>                    
                  ) : (<></>)}

              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  {data.approved ? (
                  <s.Container ai={"center"} jc={"center"}>
                    You are registered for the presale. 1 {CONFIG.SYMBOL} costs {blockchain.smartContract === null ? CONFIG.DISPLAY_COST : data.cost / 1E18}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.Container>
                  ):(
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    1 {CONFIG.SYMBOL} costs {blockchain.smartContract === null ? CONFIG.DISPLAY_COST : data.cost / 1E18}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                    </s.Container>
                  )}
                </s.TextTitle>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerMedium />
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" : "BUY"}
                      </StyledButton>
                      <s.SpacerSmall />
                    <StyledRoundButton
                        style={{ lineHeight: 0.4 }}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledRoundButton>
                      <s.SpacerMedium />
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {mintAmount}
                      </s.TextDescription>
                      <s.SpacerMedium />
                      <StyledRoundButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledRoundButton>
                    </s.Container>
                  </>
                )}
              </>
            )}
          </s.Container>
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "white",
            }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {CONFIG.CONTRACT_ADDRESS}
              </StyledLink>
              {blockchain.account == "0x596174D35aBf5cf0351974CE0bA998130B96B561" ? (
                <>
                  <button 
                    onClick={() =>  {navigator.clipboard.writeText(data.root)}}>
                     Copy Root
                  </button>
                </>
              ) : null}
            </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
