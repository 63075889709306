// log
import store from "../store";
//import Web3 from "web3";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply = Number(await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call());

      let cost = Number(await store
         .getState()
         .blockchain.smartContract.methods.cost()
         .call());

      let balance = Number(await store
      .getState()
      .blockchain.smartContract.methods.balanceOf(account)
      .call());

      let presale = await store
         .getState()
         .blockchain.smartContract.methods.presaleOpen()
         .call();

      let mainsale = await store
         .getState()
         .blockchain.smartContract.methods.publicOpen()
         .call();

      const whitelist = await fetch("/config/addresses.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const tokens = await whitelist.json();
  
      let tab = [];
      tokens.map((token) => {
        //if (Web3.utils.toChecksumAddress(token.address) != token.address) {
        //  console.log(token.address);
        //}
        tab.push(token.address);
      });
  
      const leaves = tab.map((address) => keccak256(address));
      const tree = new MerkleTree(leaves, keccak256, { sort: true });
      const root = tree.getHexRoot();
      //const leaf = keccak256("0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2");
      const leaf = keccak256(account);
      const proof = tree.getHexProof(leaf);
      //const proofstring = JSON.stringify(proof);
      //console.log("root : " + root);
      //console.log("proof : " + proof);
      //console.log("proofstring : " + proofstring);

      let approved = await store
      .getState()
      .blockchain.smartContract.methods.isValidMerkleProof(account,proof)
      .call();

      dispatch(
        fetchDataSuccess({
          totalSupply,
          cost,
          approved,
          root,
          proof,
          balance,
          presale,
          mainsale,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
